@import "../../styles/scss/globals.scss";

.modalParent {
    font-family: $font;
}

.modalBody {
    padding: 3.5rem 2rem !important;
    text-align: center;
}

.modalTitle {
    font-size: 2.25rem;
    color: $primary;
    margin-bottom: 1.5rem;
    font-weight: 600;
}

.modalIcon {
    width: auto;
    height: 4.5rem;
    margin-bottom: 1.25rem;
}

.modalDescription {
    color: $grey;
    line-height: 1.5;
    font-size: 1.25rem;
    font-weight: 500;
}

.modalBtn {
    margin-top: 0.75rem;
    margin-bottom: 0;
}
.audioInsertionPopup{
    .modalBody {
        text-align: left;
    }

    input::placeholder,
    textarea::placeholder {
        color: #999; /* Change placeholder text color */
        font-style: italic; /* Make placeholder text italic */
        font-size: 1rem; /* Change placeholder text size */
    }

    :global(.form-group-heading) {
        display: block;
        text-align: center;
    }
}
.proratedPlanPopup {
    .modalBody {
        text-align: left;
    }

    .productSummary {
        margin: 1rem 0;

        .productSummaryName {
            word-break: break-word;
            color: #1a1a1a99;
            font-size: 20px;
            font-weight: 700;
        }

        .productSummaryAmount {
            font-size: 36px;
            letter-spacing: -.03rem;
            font-variant-numeric: tabular-nums;
            font-weight: 600;
            color: #1a1a1ae6;
        }

        .productSummaryDescriptionText {
            word-break: break-word;
            color: #1a1a1a99;
            font-size: 18px;
            font-weight: 600;
        }
    }

    .lineItem{
        padding: 16px 0;
    }

    .nameWrapper {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        font-weight: 500;
        font-size: 18px;
        color: #1a1a1ae6;

        &.small {
            font-size: 16px;
            font-weight: 400;
            color: #1a1a1a80;
        }

        &.textGrey{
            color: #1a1a1a80;
        }

        .price{
            word-break: keep-all;
        }
    }

    .subtotalWrapper {
        border-top: 1px solid #1a1a1a1a;
        padding-top: 16px;
        padding-bottom: 16px;
    }

    .planDescriptionWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .confirmCTAs{
        text-align: center;
    }
}

.sharePopup {
    .modalHeader {
        border: 0;
    }

    .modalTitle {
        margin-bottom: 1.5rem;
    }

    .modalBody {
        padding-top: 2rem !important;
    }

    .singleShareButtonWrap {
        display: block;
        margin: 0 auto 1rem;
        width: 85%;
    }

    .singleShareButton {
        border-radius: 4px;
        display: flex;
        align-items: center;
        text-align: center;
        width: 100%;
        padding-left: 1.5rem;

        img {
            height: 1rem;
            display: inline-block;
            margin: 0 0.85rem 0 0;
        }
    }
}

.audioPopup {
    @extend .sharePopup;

    .modalBody {
        padding: 2.5rem 1.5rem .5rem !important;
        text-align: left;
    }

    .topHeading {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.25rem;

        .podcastName {
            font-size: 1rem;
            font-weight: 600;
            color: $primary;
            margin: 0;
        }

        .closeIcon {
            cursor: pointer;
            @include transition(0.2s);

            &:hover,
            &:focus {
                outline: none;
                box-shadow: none;
                opacity: .6;
            }
        }
    }

    .episodeCover {
        width: 100%;
        max-width: 100%;
        border-radius: .5rem;
        margin-bottom: 2.25rem;
    }

    .episodeDetails {
        color: $grey;
        font-size: 1rem;

        // max-height: 34.5vh;
        // overflow-y: auto;
        // padding-right: .25rem;
        .heading {
            font-size: 1.25rem;
            color: $primary;
            margin-bottom: .1rem;
            font-weight: 600;
        }

        .authorName {
            @extend .heading;
            font-weight: 500;
            color: $grey;
            margin-bottom: 1.375rem;
        }
    }
}

body .audioBackdrop {
    opacity: .4 !important;
    height: calc(100% - 6.5rem);
    background: #cacacc;
}

.confirmCTAs {
    margin-top: 2rem;

    .popupBtn {
        margin: 0 .35rem;

        @include max(767) {
            margin-bottom: 0.85rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.welcomeEmailModal {
    .modalDialog {
        max-width: 70rem;

        .modalBody {
            text-align: left;
            padding: 3rem 2.5rem 2rem !important;
        }
    }
}

// Responsive begin

@include max(480) {
    .modalBody {
        padding: 3rem 1.5rem;
    }

    .modalTitle {
        font-size: 1.75rem;
    }
}