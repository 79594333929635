html, body {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
a {
	color: inherit;
	text-decoration: none;
}
* {
	box-sizing: border-box;
}
::-webkit-scrollbar {
    width: 0.395rem;
	height: 0.395rem
}
::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px
}
::-webkit-scrollbar-thumb {
    background: #e5e7ea;
    border-radius: 10px
}
::-webkit-scrollbar-thumb:hover {
    background: #00cac4;
}
::-moz-selection {
	background: #0d122a;
	color: #fff;
	-webkit-text-fill-color: #fff;
	opacity: 1;
}
::selection {
	background: #0d122a;
	color: #fff;
	-webkit-text-fill-color: #fff;
	opacity: 1;
}