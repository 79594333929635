@import "../../styles/scss/globals.scss";

.table {
  margin-bottom: 3rem;

  thead th {
    border-top: 0;
    border-bottom: 1px solid;
    border-color: $lightGrey;
    color: $primary;
  }

  td, th {
    padding: 1.5rem .75rem;
    font-weight: 600;
    height: auto;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  td {
    border-color: $lightGrey;
    color: $grey;

    &:first-child, &:last-child {
      color: $primary;
    }
  }
}

.table-responsive {
  //margin-bottom: 2rem;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}